export const environment = {
  baseUrl: 'https://publinex-dev.softdirex.com',
  // Used for redirextion from client sites
  productSite: 'https://publinex-dev.softdirex.com',
  enableWebmailSiteLink: false,
  // https://mail.google.com/
  webmailSite: 'https://mail.hostinger.com/',
  production: true,
  appVersion: '1.1.1',
  localeKey: 'NIKqPYrGejeDFCcRC0Y82V',
  appName: 'Publinex',
  appDescription: 'Manage publications and content with themes associated with your organization',
  appTags: 'posts,publications,notices,blog,organizations,corporative blogs,rrss,newsletter,news,noticias,blogs corporativos,publicaciones,empresas,sitios web,website,web',
  footerInfo: 'Softdirex - Innovate your digital tools',
  officeLocation: 'La Serena - Chile',
  contactEmail: 'sales@softdirex.com',
  contactPhone: '+569 9867 2957',
  orgName: 'Softdirex',
  // Direccion web de softdirex
  providerWeb: 'https://softdirex.com/',
  // OWNER_ID Asociado al producto, si está en 0 carga datos de softdirex
  ownerId: 11,
  productId: 1,
  // BEGIN - Owner config default
  dfConfigCompanyName: 'DPR',
  dfConfigSlogan: 'Drilling & Products',
  dfConfigAbout: `Nos enorgullece darle la bienvenida a DPR y ofrecerle nuestros servicios. En colaboración con nuestras empresas metalmecánicas asociadas, nos especializamos en proporcionar aceros y fluidos de perforación de alta calidad con más de 15 años de experiencia en el sector. Nuestro compromiso fundamental es entender las necesidades de nuestros clientes y garantizar productos de la más alta calidad para satisfacer sus requerimientos.

  En lo que respecta a nuestros aceros de perforación, se fabrican con aceros de alta aleación y se procesan en tornos CNC con controles finitos para una precisión óptima. Además, aplicamos tratamientos térmicos específicos que mejoran las propiedades de nuestros productos.
  
  En cuanto al suministro de fluidos de perforación, importamos productos de primera calidad que son respetuosos con el medio ambiente. Todos nuestros procesos están documentados y cumplen con las normativas de salud y medio ambiente vigentes.
  
  Nuestra amplia experiencia en la fabricación de piezas y nuestra colaboración con socios estratégicos nos permiten ofrecer productos de alta calidad a precios competitivos, satisfaciendo efectivamente las necesidades de nuestros clientes.
  `,
  dfConfigMission: 'Queremos proyectarnos en el tiempo ofreciendo un servicio de calidad personalizado.',
  dfConfigVision: 'Marcar la diferencia proporcionando una experiencia superior, más allá de una simple venta de productos, mediante un acompañamiento técnico integral en cada interacción.',
  dfConfigContactPhone: '+569 9867 2957',
  dfConfigContactMail: 'jleiva@softdirex.com',
  dfConfigAddress: '',
  dfConfigCity: 'La Serena',
  dfConfigCountry: 'Chile',
  dfConfigTermsFilename: 'terms.json',
  dfConfigLang: 'es',
  dfConfigDaysValidQuote: 15,
  dfConfigDefaultNote: '',
  dfConfigTaxPercentage: 0,
  // END - Owner config default
  productBackendEndpoint: 'https://api-publinex-dev.softdirex.com/v1',
  coreFrontendEndpoint: 'https://escritorio-dev.softdirex.com/',
  pwdRegex: '^[\x20-\x7E]{8,30}$',
  rutRegex: '\\b[0-9|.]{1,10}\\-[K|k|0-9]',
  passportRegex: '[a-zA-Z0-9-]{6,100}',
  dniRegex: '[a-zA-Z0-9-]{6,100}',
  documentDataRegex: '[a-zA-Z0-9-]{6,100}',
  namesRegex: '[a-zA-Z\u00C0-\u00ff-\' ]{2,90}',
  phonesRegex: '[0-9+() -]{8,35}',
  addressRegex: '[a-zA-Z\u00C0-\u00ff-.@ 0-9]{2,90}',
  rrssRegex: '[a-zA-Z-/:.#@_0-9%=?&]{1,90}',
  obsRegex: '[a-zA-Z/%\u00C0-\u00ff-.,&\'\n": 0-9]{2,1000}',
  aboutRegex: '[a-zA-Z/¡!()@=?¿%\u00C0-\u00ff&-.,;\'\n": 0-9]{2,5000}',
  sessionTimeMins: 55,
  recaptcha: {
    siteKey: '6LcSKvEkAAAAAFfhUa9wcIhCFAd_P_yZFwN6lRNj',
  },
  withBackgroundBody: true,
  withFooterLogo: true,
  withNavBarLogo: true,
  map: {
    gmapsApiKey: "AIzaSyAvyilehl7RQjrDiHEoTRNI1I3JoLg2KKY",
    lat: -29.957530,
    lng: -71.265369,
    enabled: true
  },
  // Social: dejar en blanco si no existe informacion
  social: {
    youtube: '',
    instagram: '',
    x: '',
    facebook: '',
    linkedin: ''
  },
  googleClientId: '299928096210-g4r6tonbi1g9a47f798cum1muuo32e0q.apps.googleusercontent.com'
};
