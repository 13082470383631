<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-4 col-sm-6 mb-5 mb-lg-0 text-center text-sm-left mr-auto">
        <div *ngIf="!logoEnabled" class="footer-widget">
          <h4 class="mb-4">{{ ownerDetail.company_name }}</h4>
          <p class="lead">{{ ownerDetail.slogan }}</p>
        </div>
        <div *ngIf="logoEnabled" class="footer-widget">
          <img *ngIf="loadingLogo" class="img-loading mb-4 border-bottom" [src]="loadingGif">
          <img *ngIf="!loadingLogo && logoUrl==DF_LOGO" class="logo-img" src="{{logoUrl}}">
          <img *ngIf="!loadingLogo && logoUrl!=DF_LOGO" class="logo-img" [src]="logoUrl">
          <p class="lead">{{ ownerDetail.slogan }}</p>
        </div>
      </div>

      <div class="col-md-4 col-lg-4 col-sm-4 mb-5 mb-lg-0 text-center text-sm-left">
        <div class="footer-widget">
          <h4 class="mb-4">{{'label.useful-link'|transloco}}</h4>
          <ul class="pl-0 list-unstyled mb-0">
            <li><a routerLink="{{PATH_ABOUT}}">{{'label.about-us'|transloco}}</a></li>
            <li><a routerLink="{{PATH_CONTACT}}">{{'label.contact'|transloco}}</a></li>
            <li *ngIf="enableSocialLink" class="social">
              <h3>{{'label.follow-us'|transloco}}</h3>
              <a *ngIf="TWITTER_LINK != ''" href="{{TWITTER_LINK}}" target="_blank">
                <i class="tf-ion-social-twitter"></i>
              </a>
              &nbsp;
              <a *ngIf="YOUTUBE_LINK != ''" href="{{YOUTUBE_LINK}}" target="_blank">
                <i class="tf-ion-social-youtube"></i>
              </a>
              &nbsp;
              <a *ngIf="FACEBOOK_LINK != ''" href="{{FACEBOOK_LINK}}" target="_blank">
                <i class="tf-ion-social-facebook"></i>
              </a>
              &nbsp;
              <a *ngIf="INSTAGRAM_LINK != ''" href="{{INSTAGRAM_LINK}}" target="_blank">
                <i class="tf-ion-social-instagram"></i>
              </a>
              &nbsp;
              <a *ngIf="LINKEDIN_LINK != ''" href="{{LINKEDIN_LINK}}" target="_blank">
                <i class="tf-ion-social-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="!mapEnabled" class="col-md-4 col-lg-4 col-sm-4 text-center text-sm-left mr-auto">
        <div class="footer-widget">
          <h4 class="mb-4">{{'label.contact'|transloco}}</h4>
          <ul class="pl-0 list-unstyled mb-5">
            <li class="d-lg-flex justify-content-between">
              <p>
                <i class="tf-ion-ios-location"></i>{{ ' ' + location }}
              </p>
            </li>
            <li class="d-lg-flex justify-content-between">
              <p>
                <a
                  href="mailto:{{ownerDetail.contact_mail}}?subject={{'label.contact-from-page'|transloco}}{{' (' + companyName + ')'}}"><i
                    class="tf-ion-ios-email"></i>{{ ' ' + ownerDetail.contact_mail}}</a>
              </p>
            </li>
            <li *ngIf="ownerDetail.contact_phone !== '' && ownerDetail.contact_phone !== null"
              class="d-lg-flex justify-content-between">
              <h5>{{'label.call-now'|transloco}}{{' '}}{{ ownerDetail.contact_phone }}</h5>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="mapEnabled" class="col-md-4 col-lg-4 col-sm-4 text-center text-sm-left mr-auto">
        <div class="footer-widget">
          <h4 class="mb-4">{{'label.information'|transloco}}</h4>

          <ul class="pl-0 list-unstyled mb-5">
            <li class="d-lg-flex justify-content-between">
              <p>
                <a
                  href="mailto:{{ownerDetail.contact_mail}}?subject={{'label.contact-from-page'|transloco}}{{' (' + companyName + ')'}}"><i
                    class="tf-ion-ios-email"></i>{{ ' ' + ownerDetail.contact_mail}}</a>
              </p>
            </li>
            <li *ngIf="ownerDetail.contact_phone !== '' && ownerDetail.contact_phone !== null"
              class="d-lg-flex justify-content-between">
              <h5>{{'label.call-now'|transloco}}{{' '}}{{ ownerDetail.contact_phone }}</h5>
            </li>
            <li class="d-lg-flex justify-content-between">
              <p><i class="tf-ion-ios-location"></i>{{ ' ' + location }}</p>
            </li>
            <li class="d-lg-flex justify-content-between">
              <div #map class="gmap"></div>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>


<div class="footer-btm py-4 ">
  <div class="container">
    <div class="row {{(isMobile) ? 'text-center':''}}">
      <div class="col-lg-4">
        <p class="copyright mb-0 ">{{ appName + ' v' + version }} &copy; {{ anio | date: "yyyy" }} <a
            href="{{providerWeb}}" class="font-weight-bold ml-1" target="_blank">{{ ciaName }}</a>. Todos los derechos
          reservados</p>
      </div>
      <div class="col-lg-8 terms-text">
        <ul class="list-inline mb-0 footer-btm-links text-lg-right mt-2 mt-lg-0">
          <li class="list-inline-item"><a
              routerLink="{{'/'+privacyPolicyPath}}">{{'label.privacy-policy'|transloco}}</a></li>
          <li class="list-inline-item"><a
              routerLink="{{'/'+termConditionsPath}}">{{'label.terms-conditions'|transloco}}</a></li>
          <li class="list-inline-item"><a routerLink="{{'/'+cookiePolicyPath}}">{{'label.cookie-policy'|transloco}}</a>
          </li>
          <li class="list-inline-item"><a routerLink="{{'/'+termSalesPath}}">{{'label.terms-sales'|transloco}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<app-fullscreen-alert *ngIf="showAlertComponent"></app-fullscreen-alert>
