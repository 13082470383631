import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { NameFormatPipe } from './pipes/name-format.pipe';
import { TranslocoRootModule } from './transloco-root.module';
import { ViewQuoteComponent } from './components/view-quote/view-quote.component';


@NgModule({
  imports: [
    CommonModule,
    SharedRoutingModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    NameFormatPipe,
    ViewQuoteComponent,
  ],
  exports: [
    TranslocoRootModule,
    ReactiveFormsModule,
    NameFormatPipe,
    ViewQuoteComponent,
  ],
  providers: [
    NameFormatPipe,
  ],
})
export class SharedModule { }
